
import { bannerView, bannerClick, getLinkType } from '../track';
import { handleClickLink, handleHref } from '~/components/clp/util';
import { getImgUrl } from '~/utilities/helper/urlHelper';

export default {
  name: 'FeedCard',
  components: {},
  props: {
    // 传入的list数据集合
    list: {
      type: Array,
      default: () => []
    },
    // 采用埋点的数据集合
    sensorInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoad: false,
      datalist: [],
      // 图片高度
      picHeight: 0,
      // ↓插件选项配置
      swiperProgress: -1,
      // 活跃标识
      activeBanner: 0,
      swiperOption: {
        simulateTouch: false,
        slidesPerView: 4,
        spaceBetween: 2,
        breakpoints: {
          1439: {
            slidesPerView: 4
          },
          719: {
            slidesPerView: 1
          }
        },
        on: {
          slideChange: () => {
            this.swiperProgress = this.swiper.progress;
            this.activeBanner = this.swiper.activeIndex;
          }
        }
      },
      // ↑
      // mobile or pc
      device: 'mobile',
      // 计算样式
      benefitDescriptionHeight: '',
      // 计算高度
      swiperNavigationTop: '0px'
    };
  },
  computed: {
    // imgUrl不存在则不显示整个卡片
    showItem() {
      const newList = this.list.filter(Boolean);
      for (let i = 0; i < newList.length; i++) {
        if (
          !(this.device === 'mobile'
            ? newList[i].mobImgUrl
            : newList[i].pcImgUrl)
        ) {
          newList.splice(i, 1);
        }
      }
      return newList;
    },
    swiper() {
      return this.$refs?.mySwiper?.swiper;
    },
    // 显示右边可点击
    showRightArrow() {
      if (this.showItem?.length > 4) {
        return false;
      } else {
        return true;
      }
    },
    getImg() {
      return function (item) {
        return this.device === 'mobile'
          ? getImgUrl(item.mobImgUrl, 500)
          : getImgUrl(item.pcImgUrl, 500);
      };
    },

    handleUrl() {
      return function (item) {
        if (!item.photoLink) return 'javascript:void(0);';
        return handleHref(item.photoLink);
      };
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.sensorViewTrack();
  },
  beforeDestroy() {
    this.swiper&&this.swiper.destroy(false, false);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // 添加埋点
    sensorViewTrack() {
      const { bannerId, bannerName, bannerPosition } = this.sensorInfo;
      bannerView(bannerId, bannerName, bannerPosition);
    },
    sensorClickTrack(type, data) {
      const { bannerId, bannerName, bannerPosition } = this.sensorInfo;
      const { photoLink, buttonLink } = data;
      const linkType = getLinkType(type === 'Image' ? photoLink : buttonLink);
      bannerClick({
        bannerClickType: type,
        bannerId,
        bannerName,
        bannerPosition,
        bannerToPageType: linkType,
        bannerToUrl: type === 'Image' ? photoLink : buttonLink
      });
    },
    handleResize() {
      // 判断配置高度
      const windowSize = window.innerWidth;
      let device;
      if (windowSize < 720) {
        device = 'mobile';
      } else {
        device = 'pc';
      }
      this.device = device;

      // 设置完轮播图属性 动态获取的图片高度确定导航的绝对位置
      if (!document.getElementsByClassName('benefit-banner')[0]) {
        return;
      }
      const imgDom = document.getElementsByClassName('benefit-banner')[0];
      const sliderWidth = this.$refs.slider && this.$refs.slider[0] && this.$refs.slider[0].style.width || 0;
      const width = parseInt(imgDom.clientWidth || sliderWidth);
      if (!imgDom) return;
      this.picHeight = (width / 5) * 6;
      this.swiperNavigationTop = imgDom.clientHeight / 2 - 16 + 'px';
    },
    gotoMore(item) {
      if (!item.buttonLink) return;
      handleClickLink.call(this, { ...item, link: item.buttonLink });
      this.sensorClickTrack('Button', item);
    },
    // 配合插件选项配置
    guid(step) {
      this.swiper.slideTo(step, 500, false);
    },
    flipSwiper(direction) {
      let pagination;
      switch (this.device) {
        case 'mobile':
          pagination = 1;
          break;
        default:
          pagination = 4;
      }
      this.guid(
        this.activeBanner + (direction === 'prev' ? -pagination : pagination)
      );
    },
    // 图片跳转
    imgJump(item) {
      if (!item.photoLink) return;
      handleClickLink.call(this, { ...item, link: item.photoLink });
      this.sensorClickTrack('Image', item);
    },
    // 图片的加载状态
    handleLoad(index) {
      this.isLoad = true;
      if (index < 2) this.handleResize();
    }
  }
};
