import Vue from 'vue';
import downloadView from './index.vue';

let newInstance = null;

// 将vue组件变为构造函数
const ApplyConstructor = Vue.extend(downloadView);

const init = (orderId, orderItem)=>{
  // 实例化组件
  newInstance = new ApplyConstructor();
  // 合并配置选项
  Object.assign(newInstance);
  // 使用$mount()后  可以理解为创建虚拟的dom
  document.body.appendChild(newInstance.$mount().$el);
};
const caller = ()=>{
  if(!newInstance){
    init();
  }
  return newInstance.show(vm =>{newInstance = null;});
};
export default new Vue({
  name: 'WxAppDownload',
  methods: {
    show() {
      caller();
    }
  }
});
