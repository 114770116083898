
import { mapState } from 'vuex';
import deepcopy from 'deepcopy';
import * as myAccountTrack from './track';
import feedProduct from '~/components/homepage/feed-product';
import feedCard from '~/components/homepage/feedCard';
import Carousel from '~/components/homepage/banner/carousel.vue';
import NBanner from '~/components/homepage/n-banner';
import ClpMultiImage from '~/components/homepage/clp-multi-image/ClpMultiImage';
import tagLine from '~/components/homepage/tag-line/TagLine.vue';
import footPrint from '~/components/homepage/foot-print';
import { isLogin, setSeo } from '~/utilities';
import { getUrlQuery } from '~/utilities/helper/urlHelper';
import mixins from '~/plugins/wxConfigMixin';
import Promo from '~/components/homepage/promo-card/index.vue';
import { receiveCoupon } from '~/components/common/promo-redemption/ssr.async.data';
import { sortCouponData, Utils, findConfig } from '~/components/homepage/utils';
import { couponToastView, bannerView } from '~/components/homepage/track';

const setSeoInfo = (data, ctx) => {
  const { seoDescription, seoKeyword, seoNoFollow, seoTitle } = data;
  const seoInfo = { seoDescription, seoKeyword, seoNoFollow, seoTitle };
  (ctx.store || ctx.$store).commit('homePage/SET_SEO_INFO', seoInfo);
};

const getHomepageLayer = (ctx, renderSide) => {
  return ctx.$axios.get(
    `${ctx.$config.S3_HOST}/f04/page_conf/COM/zh_CN/${
      renderSide === 'client' ? 'stage' : 'online'
    }/0/2.json`
  );
};

const getSearchList = (data, ctx, renderSide) => {
  const { categoryCode, keyword } = getUrlQuery(data.link);
  const params = {
    page: 1,
    pageSize: data.pageSize,
    abTest: 'A',
    categoryCode,
    keyword
  };
  return new Promise((resolve, reject) => {
    ctx.$axios
      .get(
        `${ctx.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/search`,
        {
          params: {
            ...params,
            preview: renderSide === 'client' ? '0' : null
          }
        }
      )
      .then((res) => {
        if (!res.content) res.content = [];
        resolve(res);
      })
      .catch((e) => {
        resolve({});
      });
  });
};

const getFeedList = (feedList, ctx, renderSide) => {
  return ctx.$axios.post(
    `${ctx.$config.ECP_API_PRIVATE_HOST}/o2cms/v1/pub/feed`,
    {
      feedCodes: feedList,
      preview: renderSide === 'client' ? '0' : null
    },
    {
      headers: {
        'x-preview-flag': 0
      }
    }
  );
};

const getHomepageData = async (ctx, renderSide) => {
  try {
    // 获取楼层信息
    const data = await getHomepageLayer(ctx, renderSide);
    // 存储seo信息
    setSeoInfo(data, ctx);

    const componentsDetail = [];
    const componentsDetailMobile = [];
    const componentsDetailPC = [];
    const relatedFeed = [];
    const feedProductList = {};
    const componentsData = [];
    // 设置一个计数器判定是否所有商品列表接口请求完成

    // 获取所有的feedCode, 统一请求
    let renderPosition = 0;
    data.layout.forEach((el, index) => {
      const _key = el.component[0].componentCode;
      const _value = JSON.parse(el.component[0].options || '{}');
      let _viewport = _value?.terminal || 'pc,mobile';
      _viewport = _viewport.toLowerCase();
      _viewport = _viewport.split(',').filter((it) => {
        return it === 'pc' || it === 'mobile';
      });
      const _class =
        _viewport.includes('mobile') && _viewport.includes('pc')
          ? ''
          : _viewport.includes('mobile')
          ? 'mobile-only'
          : _viewport.includes('pc') ? 'desktop-only' : '';
      if (_key === 'FeedProduct')
        feedProductList[index] = Array.isArray(_value)
          ? _value
          : _value.itemList;
      if (_key.toLowerCase().startsWith('feed')) {
        // 详情接口里的数据已经是全部(不需要再调用其他接口)
        if (_key === 'FeedCom') {
          relatedFeed.push(..._value.relatedFeedList);
        } else if (_key === 'FeedProduct') {
          // 初始化需要请求商品列表接口的数量
          const _val = Array.isArray(_value) ? _value : _value.itemList;
          relatedFeed.push(..._val.map((el) => el.relatedFeed));
        } else if(_value.relatedFeed) {
          // _value.relatedFeed 是数组或者字符串(只有一个)
          // eslint-disable-next-line no-proto
          if (_value.relatedFeed.__proto__.constructor.name === 'String') {
            relatedFeed.push(_value.relatedFeed);
          } else {
            relatedFeed.push(..._value.relatedFeed);
          }
          // 如果详情接口调完还需要调另一个接口, 当前代表的就是FeedProduct
          // eslint-disable-next-line no-proto
        }
      }

      // #region sensor 埋点信息
      const preComponent = data.layout[index - 1]?.component[0];
      const bannerName =
        preComponent && preComponent.componentCode === 'TAGLINE'
          ? JSON.parse(preComponent.options || '{}').headDescription
          : undefined;
      if (
        [
          'FeedCom',
          'FeedProduct',
          'FeedCard',
          'NBanner',
          'ClpMultiImage'
        ].includes(_key)
      )
        renderPosition++;
      const sensorInfo = {
        bannerPosition: renderPosition,
        bannerName,
        bannerId: `${_key}_${el.layoutPositionCode}`
      };
      // #endregion 埋点信息获取结束

      // 将数据整理称componentsData
      componentsData.push({
        code: _key,
        options: _value,
        viewport: _viewport,
        class: _class,
        sensorInfo
      });
    });

    // 并行请求列表
    const promises = [];
    // 获取feed详情列表
    if (relatedFeed.length) {
      promises.push(
        getFeedList(relatedFeed, ctx, renderSide)
      );
    }

    // 获取feedProduct 商品列表
    for (const el of Object.values(feedProductList)) {
      for (let j = 0; j < el.length; j++) {
        promises.push(getSearchList(el[j], ctx, renderSide));
      }
    }

    // 获取所有feed详情
    const allFeedData = await Promise.all(promises);
    const details = {};
    if (relatedFeed.length) {
      const feedsDetailData = allFeedData.shift();
      feedsDetailData.content?.forEach((el) => {
        details[el.feedCode] = el;
      });
    }
    // 重构数据结构
    data.layout.forEach((el, index) => {
      const _key = el.component[0].componentCode;
      const _value = JSON.parse(el.component[0].options || '{}');

      if (_key.toLowerCase().startsWith('feed')) {
        // 详情接口里的数据已经是全部
        if (_key === 'FeedCom') {
          const detail = _value.relatedFeedList.map((el) => {
            return details[el];
          }).filter(item => {return item;});
          componentsDetail.push(detail);
        } else if (_key === 'FeedProduct') {
          // FeedProduct组件, 额外需要商品列表数据
          const _val = Array.isArray(_value) ? _value : _value.itemList;
          const data = _val.map((el) => {
            const detail = details[el.relatedFeed] || {};
            return {
              ...el,
              ...detail,
              products: allFeedData.shift().content?.map((_product) => {
                delete _product.labelList;
                delete _product.recommendedPercentage;
                delete _product.subTitle;
                return _product;
              }) || [{}, {}, {}]
            };
          });
          componentsDetail.push(data);
        } else if (_value.relatedFeed) {
          // eslint-disable-next-line no-proto
          if (_value.relatedFeed.__proto__.constructor.name === 'String') {
            // _value.relatedFeed 是数组或者字符串(只有一个)
            const detail = details[_value.relatedFeed];
            if (detail) {
              componentsDetail.push(detail);
            }
          } else {
            const detail = _value.relatedFeed.map((el) => details[el]).filter(item => {return item;});
            // 有多个relatedFeed
            componentsDetail.push(detail);
          }
          // eslint-disable-next-line no-proto
        }
      } else {
        componentsDetail.push(_value);
      }
    });
    const tempCompsDetail = deepcopy(componentsDetail);
    tempCompsDetail.forEach((detail, index) => {
      const tempDetail = deepcopy(detail);
      const componentCode = componentsData[index].code;
      if (componentCode === 'FeedCom') {
        const dataMobile = deepcopy(tempDetail.filter((item) => {
          return item?.terminal?.toLowerCase()&&item.terminal.toLowerCase().includes('mobile');
        }));
        const dataPC = deepcopy(tempDetail.filter((item) => {
          return item?.terminal?.toLowerCase()&&item.terminal.toLowerCase().includes('pc');
        }));

        dataMobile.forEach((item) => {
          item.imgPath = item.mobImgUrl;
        });
        dataPC.forEach((item) => {
          item.imgPath = item.pcImgUrl;
        });
        componentsDetailMobile.push(
          dataMobile
        );
        componentsDetailPC.push(
          dataPC
        );
      } else {
        componentsDetailMobile.push(tempDetail);
        componentsDetailPC.push(tempDetail);
      }
    });
    const _store = ctx.store || ctx.$store;
    _store.commit('homePage/SET_FEED_PRODUCT', feedProductList);
    _store.commit('homePage/SET_COMPONENTS_DETAIL', componentsDetail);
    _store.commit('homePage/SET_COMPONENTS_DETAIL_MOBILE', componentsDetailMobile);
    _store.commit('homePage/SET_COMPONENTS_DETAIL_PC', componentsDetailPC);
    _store.commit('homePage/SET_COMPONENTS_DATA', componentsData);
  } catch (e) {
    console.log(e);
  }
};

export default {
  name: 'Home',
  components: {
    NBanner,
    feedCard,
    feedProduct,
    ClpMultiImage,
    Carousel,
    tagLine,
    Promo,
    footPrint
  },
  mixins: [mixins],
  async asyncData(ctx) {
    try {
      // 有缓存时不请求
      if (
        ctx.nuxtState &&
        ctx.nuxtState.state.homePage.componentsDetail.length
      ) {
        return {};
      }
      if (ctx.query.preview !== '1') await getHomepageData(ctx, 'server');
      // 获取组件接口和的
    } catch (e) {
      console.log(e);
    }
  },
  data(){
    return {
      // 优惠券模块S3配置
      promoRedemptionConfig:null,
      // 弹窗优惠券列表
      couponList:[],
      // 优惠券组件的优惠券数据
      moduleCouponList:[],
      showCard: true,
      successFlag: false,
      animationNameProps:"",
      isMobile: false
    };
  },
  head() {
    // console.warn('---', this);

    return setSeo('homepage', {
      title: this.$store.state.homePage.seoInfo.seoTitle || ''
    });
  },
  computed: {
    ...mapState('myAccount', ['isLogin','isEmployeeLogin', 'loggingOut', 'employeeLoggingOut']),
    ...mapState('homePage', [
      'componentsDetail',
      'componentsDetailMobile',
      'componentsDetailPC',
      'componentsData',
      'feedProductList'
    ]),
    isNonmemberLogin() {
      return !!this.$store.state.myAccount.isNonmemberLogin;
    },
    footPrintIndex() {
      const componentsData = deepcopy(
        this.$store.state.homePage.componentsData || []
      );
      for (let i = componentsData.length - 1; i >= 0; i--) {
        if (
          [
            'FeedCom',
            'FeedProduct',
            'FeedCard',
            'NBanner',
            'ClpMultiImage',
            'TAGLINE'
          ].includes(componentsData[i].code)
        ) {
          if (componentsData[i - 1]?.code === 'TAGLINE') {
            return i - 1;
          } else {
            return i;
          }
        }
      }
      return undefined;
    }
  },
  async mounted() {
    this.$aloading.hide();
    if (!isLogin()) {
      this.$store.dispatch('myAccount/updateLoginStatus');
      this.$store.dispatch('myAccount/updateEmployeeLoginStatus', false);
      this.$store.dispatch('myAccount/updateNonmemberLoginStatus', false);
      this.$store.commit('myAccount/SET_EMPLOYEE_INFO', {});
      this.$store.commit('myAccount/SET_ACCOUNT_INFO', {});
      this.$store.commit('myAccount/SET_ORDER_INFO', {
        toBePay: 0,
        toBeDelivered: 0,
        toBeReceived: 0,
        toBeRate: 0
      });
      if (this.loggingOut) {
        this.$Toast({ message: '已退出' });
        this.$store.commit('myAccount/SET_LOGOUT_STATUS', false);
        // 再次进入时需要重新加载骨架屏
        this.$store.commit('myAccount/SET_SKELETON_STATUS', true);
        this.$store.dispatch('homePage/getCartNum', { ctx: this });
      }
      if (this.employeeLoggingOut) {
        this.$Toast({ message: '退出成功' });
        this.$store.commit('myAccount/SET_EMPLOYEE_LOGOUT_STATUS', false);
        // 再次进入时需要重新加载骨架屏
        this.$store.commit('myAccount/SET_SKELETON_STATUS', true);
        this.$store.dispatch('homePage/getCartNum', { ctx: this });
      }
    }
    if (this.$route.query.preview === '1') {
      this.initHomepage();
    }
    this.fetchWxInitData({
      title: '阿迪达斯官方商城'
    });
    // 如果存在优惠券详情组件，则去查询优惠券S3配置以及列表数据
    if (this.componentsData.filter(item => item.code === "InnerpagePromocodeCom").length !== 0) {
      await Promise.all([
        this.getConfig(),
        this.getPromoCardList().then(()=>{
          this.showCard = this.moduleCouponList.length > 0;
        })
      ]);

    }
    this.feedComSensorViewTrack();
  },
  methods: {
    // 获取首页优惠券S3配置
    async getConfig() {
      const defaultConfig = {
        "promoHandPath": `${this.$config.S3_COM_HOST}/assets/homepage/promo-hand.png`,
        "promoPopUpBgPath": `${this.$config.S3_COM_HOST}/assets/homepage/promo-pop-up-bg.png`,
        "promoModuleMobileBgPath": `${this.$config.S3_COM_HOST}/assets/homepage/pro-module-mobile-bg.png`,
        "promoModulePcBgPath": `${this.$config.S3_COM_HOST}/assets/homepage/promo-module-pc-bg.png`,
        "startTime": "2023-03-08T00:00:00",
        "popupWaitingTime": 1,
        "enable": 0
      };
      const promoRedemptionConfig = await this.$axios.get(`${this.$config.S3_COM_HOST}/static/promo/promoRedemptionPopupConfig.json`);
      this.promoRedemptionConfig = { ...defaultConfig, ...findConfig(promoRedemptionConfig.promoRedemptionPopupConfig) };
    },
    async handleReceiveCoupon(popupFlag,afterLoginFlag) {
      try {
        this.$aloading.show();
        // 这里弹窗点了领券登录后，数据需要查带领取的（不过滤未使用的）
        const promoIds = (popupFlag && !afterLoginFlag?this.couponList: this.moduleCouponList).map((item) => { return item.promoCodeId; });
        const res = await receiveCoupon(this, promoIds);
        // this.showCard = false;
        this.$aloading.hide();
        const errorList = res.filter((item) => {
          return !item.success;
        }) || [];
        let successFlag = false;
        if (errorList.length > 0 && errorList.length < promoIds.length) {
          this.$Toast({ message: '部分优惠券领取失败'});
          this.trackCouponToastView("部分优惠券领取失败");
        } else if (errorList.length === promoIds.length){
          this.$Toast({ message: '领取失败'});
          this.trackCouponToastView("领取失败");
        } else {
          this.$Toast({ htmlMsg: '领取成功<br />可前往“我的”->“可用优惠”查看', message: '',width: window.innerWidth < 720?"66%":"auto"});
          this.trackCouponToastView("领取成功可前往“我的”->“可用优惠”查看");
          successFlag = true;
        }
        this.successFlag = successFlag;
        this.receiveCouponTrack(successFlag,popupFlag);
        !successFlag && await this.getPromoCardList();
      } catch (e) {
        // this.showCard = false;
        this.$aloading.hide();
        this.$Toast({ message: '领取失败' });
        console.error('领取pdp优惠码失败: ' + e);
      } finally {
        this.animationNameProps = 'leave-to';
      }
    },
    receiveCouponTrack(successFlag,popupFlag) {
      // const myAccountTrack = require('~/pages/index/track/index.ts');
      const couponAmount = [];
      const couponId = [];
      const couponName = [];
      // todo 这里数据源取值逻辑待定，是取弹窗还是组件(主要是立即登录后的提交领券是取什么呢？)？
      const couponList = popupFlag? this.couponList : this.moduleCouponList;
      couponList.forEach((item) => {
        couponAmount.push(item.discount || item.reduction);
        couponId.push(item.promoCodeId);
        couponName.push(item.promoCodeName);
      });
      const obj = {
        couponamount: `[${couponAmount.toString()}]`,
        couponid: `[${couponId.toString()}]`,
        couponname: `[${couponName.toString()}]`,
        coupontype: '优惠码',
        pageSource: popupFlag? 'HompagePopup':'HomepageModule',
        isValid: successFlag
      };
      // console.log("埋点couponList",couponList,obj);
      try {
        myAccountTrack.CouponRecieveClick(obj);
      } catch (e) {
        console.warn('CouponRecieveClick', e);
      }
    },
    // eslint-disable-next-line require-await
    async handleLoginMember(popupFlag) {
      console.log("0719-last",popupFlag);
      this.$login({
        show: true,
        on: {
          login: async () => {
            this.$aloading.show();
            await this.getPromoCardList();
            if (!this.moduleCouponList.length) {
              this.$Toast({ message: '暂无有效优惠券可领取' });
              this.trackCouponToastView("暂无有效优惠券可领取");
            } else {
              await this.handleReceiveCoupon(popupFlag,true);
            }
          }
        },
        joinAdiclubProp: true
      });
    },
    trackCouponToastView(name){
      couponToastView("homepage",name);
    },
    async getPromoCardList() {
      const promoData = this.componentsData?.find((item) => {
        return item.code === 'InnerpagePromocodeCom';
      });
      const options = promoData?.options || {};
      const promoSceneList = [
        {
          promoSceneCode: options?.sceneCode,
          subPromoSceneCode: options?.subSceneCode,
          cieFlag: options?.cieFlag,
          limitFlag: options?.limitFlag
        }
      ];
      const utils = new Utils(this);
      const responseData = await utils.getPromoCardList(promoSceneList);
      this.couponList = sortCouponData(responseData.promoSceneList[0].promoCodeList || [], true);
      this.moduleCouponList = sortCouponData(responseData.promoSceneList[0].promoCodeList || [], false);
    },
    async initHomepage() {
      const waitCheckPermission=this.$store.state.homePage.waitCheck;
      if(waitCheckPermission){
        await waitCheckPermission;
      }
      if (this.isLogin) {
        this.checkPermission();
      } else {
       const checkPermission= new Promise(resolve => {
          this.$login({
            show: true,
            on: {
              newLoginEnd: () => {
                if (this.isNonmemberLogin) {
                  this.$store.dispatch('myAccount/afterNonmemberLogin', {
                    ctx: this
                  });
                } else {
                  this.$store.dispatch('myAccount/afterLogin', { ctx: this });
                }
                this.checkPermission();
                resolve();
              },
              close() {
                // 这个坑爹的方法会在 login前先运行
                resolve(false);
              }
            }
          });
        });
        // this.$store.commit('homePage/SET_WAIT_CHECK', checkPermission);
        await checkPermission;
      }
    },
    async checkPermission() {
      this.$aloading.show({ type: 'indicator' });
      const hasPermission = await this.$axios.get(
        `${this.$config.ECP_API_PRIVATE_HOST}/o2cms/v1/cms/preview/permission-check`
      );
      if (hasPermission.prePermissionFlag) {
        this.$store.commit('homePage/SET_PREVIEW_PERMISSION', true);
        await getHomepageData(this, 'client');
        // 如果存在优惠券详情组件，则去查询优惠券S3配置以及列表数据
        if (this.componentsData.filter(item => item.code === "InnerpagePromocodeCom").length !== 0) {
          await Promise.all([
            this.getConfig(),
            this.getPromoCardList().then(()=>{
              this.showCard = this.moduleCouponList.length > 0;
            })
          ]);

        }
      } else {
        this.$router.push('error');
      }

      this.$aloading.hide();
    },
    feedComSensorViewTrack() {
      this.componentsData.forEach((component, index) => {
        if (component.code === 'FeedCom') {
          const carouselData = this.componentsDetail[index];
          const { bannerPosition } = component.sensorInfo;
          const links = [];
          const headDescriptions = [];
          carouselData.forEach((item, index) => {
            if(item){
              headDescriptions.push(item.headDescription);
              links.push(item.photoLink || item.buttonLink);
            }
          });
          const headDescription = headDescriptions.join('|');
          const bannerId = 'FeedCom_' + bannerPosition + '_' + 'total' + carouselData.length;
          const link = links.join('|');
          bannerView(
            bannerId,
            headDescription,
            bannerPosition,
            link
          );
        }
      });
    }
  }
};
