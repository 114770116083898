
import debounce from 'lodash.debounce';
import { nBannerView, nBannerClick } from '../track';
import { handleClickLink, handleHref } from '~/components/clp/util';
import { getImgUrl } from '~/utilities/helper/urlHelper';

export default {
  name: 'Nbanner',
  props: {
    // banner数据源
    data: {},
    sensorInfo: {
      // 神策埋点信息
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
       // 移动端标识
      isMobile: false,
      // 图片加载完成标识
      isLoad: false,
      // 一行N张
      row: 4,
      activeBanner: 0,
      swiperProgress: -1,
      height: 0
    };
  },
  computed: {
    mulPhotoType() {
      const { mulPhotoType = '' } = this.data;

      return mulPhotoType.toLowerCase();
    },

    showNbanner() {
      // 只展示这几种类型，其他不展示 layoutType 不配也不展示
      const { mulPhotoType = '' } = this.data;

      // 固定写死比例+N的关联关系，不符合的通通不展示
      const ordinaryShow = mulPhotoType.toLowerCase() === 'ordinary' && ((this.data.layoutType === 3 && this.data.ratio === '3:2') || (this.data.layoutType === 4 && ['1:1', '3:4', '3:2'].includes(this.data.ratio)));
      const franchiseShow = mulPhotoType.toLowerCase() === 'franchise' && ((!this.isMobile && (this.data.layoutType === 4 || this.data.layoutType === 3)) || (this.isMobile && this.data.layoutType > 0 && this.data.layoutType < 4));
      // brand模式 pc端只支持n=2-9 mobile端支持2，3，4
      const brandShow = mulPhotoType.toLowerCase() === 'brand' && ((this.data.layoutType > 2 && this.data.layoutType <= 9 && !this.isMobile) ||
        (this.data.layoutType > 1 && this.data.layoutType < 5 && this.isMobile));

      return ordinaryShow || franchiseShow || brandShow;
    },

    swiper() {
      return this.$refs.mySwiper.swiper;
    },

    swiperOption() {
      return {
        simulateTouch: false,
        spaceBetween: 2,
        slidesPerView: this.data.layoutType,
        slidesPerGroup: this.data.layoutType,

        breakpoints: {
          1439: {
            slidesPerView: this.data.layoutType
          },
          719: {
            slidesPerView: 'auto',
            freeMode: true
          }
        },
        navigation: {
          nextEl: '.navigation-right',
          prevEl: '.navigation-left'
        },
        on: {
          slideChange: () => {
            this.swiperProgress = this.swiper.progress;
            this.activeBanner = this.swiper.activeIndex;
          }
        }
      };
    },

    // 显示右边可点击
    showRightArrow() {
      if (this.imgList.length > this.data.layoutType) {
        return false;
      } else {
        return true;
      }
    },

    imgList() {
      // 数据中要过滤掉没有图片链接的数据，下同
      let data = [];
      if (this.isMobile) {
        data = this.data.itemList.filter((item) => {
          return item.mobImgUrl;
        });
      } else {
        data = this.data.itemList.filter((item) => {
          return item.pcImgUrl;
        });
      }
      return data;
    },
    // 图片加载计算
    imgLoad() {
      return this.isLoad && 'fadein';
    },
    handleUrl() {
      return function (item) {
        if (!item.link) { return 'javascript:void(0);'; }
        return handleHref(item.link);
      };
    },

    getResizeImgUrl() {
      return function (url) {
        return getImgUrl(url, 1000);
      };
    },

    brandWidth() {
      const { layoutType } = this.data;
      return this.isMobile ? '' : {width: `calc(100% / ${layoutType})`};
    }
  },
  mounted() {
    window.addEventListener('resize', this.getWindowsWidthAndHeight);
    this.getWindowsWidthAndHeight();
    if(this.data.mulPhotoType !== 'brand') {
      this.setImgHeight();
    }

    if(this.showNbanner) {
      this.$on(
        'hook:beforeDestroy',
        this.$utils.device.onPageScroll(debounce(this.sensrosOnScroll, 300))
      );
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowsWidthAndHeight);
  },
  methods: {
    // 点击时触发查看神策埋点
    sensorClickTrack(data, index) {
      const { bannerName = '' } = this.sensorInfo;
      const { link, imgDescription  } = data;
      const { mulPhotoType } = this.data;

      const param = {
        type: mulPhotoType.toLowerCase(),
        component_code: 'NBanner',
        subject: bannerName,
        position: index + 1
      };

      if(param.type === 'franchise') {
        param.name = imgDescription;
        param.to_url = link;
      }

      if(param.type === 'brand') {
        param.image_url = link;
      }

      if(param.type === 'ordinary') {
        param.img_description = imgDescription;
      }

      nBannerClick(param);
    },

    // 点击跳转详情链接
    goDetail(item, index) {
      if (!item.link) { return; }
      this.sensorClickTrack(item, index);
      handleClickLink.call(this, item);
    },
    // 图片展示标识计算：在对应的端图片链接不为空则展示
    showFlag(item) {
      let show = false;
      if (
        (this.isMobile && item.mobImgUrl) ||
        (!this.isMobile && item.pcImgUrl)
      ) {
        show = true;
      }
      return show;
    },
    // 设定移动端判断标识
    getWindowsWidthAndHeight() {
      this.isMobile = window.innerWidth < 720;
    },
    // 图片加载完成触发
    onLoad() {
      this.isLoad = true;
    },
    // 根据ratio确定图片高度
    setImgHeight() {
      const {ratio, layoutType, mulPhotoType = ''} = this.data;
      const w = ratio ? Number(ratio.split(':')[0]) : 1;
      const h = ratio ? Number(ratio.split(':')[1]) : 1;
      const franchiseRatio = (this.isMobile && layoutType === 1) || (!this.isMobile && layoutType === 3) ? 16/9 : 3/2;
      const finalRatio = mulPhotoType.toLowerCase() === 'franchise' ? franchiseRatio : w/h;
      // 平铺 移动端 N = 3 的时候才会在<=3条数据的时候竖向排列 --- 这段逻辑不要了
      // const row = !this.isMobile ? Number(layoutType) : this.imgList.length <= 3 && layoutType === 3 && mulPhotoType.toLowerCase() === 'franchise' ? 1 : 2;
      const row = Number(layoutType);
      const mobileItemWidth = mulPhotoType.toLowerCase() === 'ordinary' ? 200 : (window.innerWidth - 32) / row;
      const pcItemWidth = window.innerWidth < 1440 ? (window.innerWidth - 80 - 2 * (Number(layoutType) - 1)) / Number(layoutType) : (1440 - 80 - 2 * (Number(layoutType) - 1)) / Number(layoutType);
      const itemWidth = this.isMobile ? mobileItemWidth : pcItemWidth;

      this.height = itemWidth / finalRatio + 'px';
    },
    // 拼接所有的name list for sensors
    getBannerNameList() {
      const list = this.imgList.map((item) => {
        return item.imgDescription;
      });

      return list.join('|');
    },
    // 滚动事件上报nbanner view
    sensrosOnScroll() {
      const { scrollTop } = this.$utils.device.getScrollTop();
      const { bannerName } = this.sensorInfo;
      const { mulPhotoType } = this.data;
      const param = {
        type: mulPhotoType.toLowerCase(),
        component_code: 'NBanner',
        subject: bannerName
      };

      if(param.type === 'franchise') {
        param.name_list = this.getBannerNameList();
      }

      try{
        // 124 = header height
        if(scrollTop < this.$refs.nBannerRef.offsetTop + this.$refs.nBannerRef.clientHeight + 124 && scrollTop > this.$refs.nBannerRef.offsetTop - window.innerHeight + 124) {
          nBannerView(param);
        }
      } catch(e) {}
    }
  }
};
