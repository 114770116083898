
import { bannerView } from '../track';
import WxAppDownload from '../../my-account/wx-app-download';
import { getImgUrl } from '~/utilities/helper/urlHelper';

export default {
  props: {
    clpMultiImage: {
      type: Object,
      default: () => {
        return {};
      }
    },
    sensorInfo: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isLoad: true,
      isLoad2: true,
      isMobile: 'false',
      downLoadUrl:
        'https://a.app.qq.com/o/simple.jsp?pkgname=cn.adidas.app&channel=0002160650432d595942&fromcase=60001',
      confirmedUrl: 'https://analytics.adidas.com.cn/r/cJ',
      tagLine: {
        headDescription: '会员专属发售平台',
        linkDescription: '',
        link: ''
      }
    };
  },
  computed: {
    getConfirmed() {
      if (this.clpMultiImage?.itemList.length > 1) {
        return true;
      } else {
        return false;
      }
    },
    // 获取图片、标签alt
    showImage() {
      return function(item) {
        const ImgUrl = this.isMobile
            ? item.mobImgUrl
            : item.pcImgUrl;
        const SeoTag = this.isMobile
          ? item.mobSeoTag
          : item.pcSeoTag;
        return {ImgUrl, SeoTag};
      };
    },

    mainTitleObj() {
      return function(item) {
        const { color, size, bold } = item.title;
        return {
          color: color || '#000',
          fontSize: size || '15px',
          fontWeight: bold ? '700' : '700'
        };
      };
    },
    subTitleObj() {
      return function(item) {
        const { color, size, bold } = item.subtitle;
        return {
          color: color || '#000',
          fontSize: size || '13px',
          fontWeight: bold ? '700' : '400'
        };
      };

    },
    buttonObj() {
      return function(item) {
        const { textColor, size, bold, color } = item.button;
        return {
          color: textColor || '#fff',
          fontSize: size || '14px',
          fontWeight: bold ? '700' : '700',
          backgroundColor: color || '#000'
        };
      };
    },
    handleHref() {
      return function (link) {
        if (!link) return 'javascript:void(0);';
        return link;
      };
    },
    getResizeImgUrl() {
      return function (item) {
        const ImgUrl = this.isMobile
            ? item.mobImgUrl
            : item.pcImgUrl;

        return getImgUrl(ImgUrl, 1000);
      };
    }
  },

  beforeMount() {
    this.handleWidth();
    window.addEventListener('resize', this.handleWidth);
  },
  mounted() {
    this.sensorViewTrack();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWidth);
  },

  methods: {
    sensorViewTrack() {
      const { bannerId, bannerName, bannerPosition } = this.sensorInfo;
      bannerView(bannerId, bannerName, bannerPosition);
    },
    changeRouter(url) {
      if (this.isWeiXin()) {
        WxAppDownload.show();
      } else {
        location.href = url;
      }
    },
    handleWidth() {
      this.isMobile = window.innerWidth < 720;
    },
    // 判断是否是微信内置浏览器
    isWeiXin() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        return ua.match(/MicroMessenger/i)[0] === 'micromessenger';
      } else {
        return false;
      }
    }
  }
};
