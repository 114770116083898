/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2023-02-23 15:47:06
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2023-02-28 14:41:00
 * @FilePath: /adidas-com-front/packages/nuxt/components/common/promo-redemption/ssr.async.data.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 批量领取优惠码
 * @param ctx
 * @return {Promise<T>}
 */
export async function receiveCoupon(ctx, ids) {
  try {
    const data = await ctx.$axios.post(`${ctx.$config.ECP_API_HOST}/o2pmt/v1/promocode/acquire/batch`,
      ids
    );
    console.log('cart批量领取优惠码', data);
    return data;
  } catch (e) {
    console.error('cart批量领取优惠码失败: ' + e);
    return [];
  }
}
