/*
 * @Author: dezhao.zhao@hand-china.com
 * @Date: 2021-10-21 16:56:53
 * @Description: my-account埋点混入
 */

let myAccountTrack;
if (process.client) {
  myAccountTrack = require('./index.ts');
}

export default {
  methods: {
    // 浏览“我的”页面触发
    trackMyAccountView(obj) {
      try {
        myAccountTrack.MyAccountView(obj);
      } catch (e) {
        console.warn('trackMyAccountView', e);
      }
    },
    // 用户浏览最近浏览触发
    trackRecentView(obj) {
      try {
        myAccountTrack.RecentView(obj);
      } catch (e) {
        console.warn('trackMyAccountView', e);
      }
    },
    // 用户点击最近浏览中的产品时触发
    trackRecentClick(obj) {
      try {
        myAccountTrack.RecentClick(obj);
      } catch (e) {
        console.warn('trackRecentClick', e);
      }
    },
    // 用户点击优惠券列表里的立即使用触发
    trackCouponUse(obj) {
      try {
        myAccountTrack.CouponUse(obj);
      } catch (e) {
        console.warn('trackCouponUse', e);
      }
    },
    // 浏览优惠券/码列表时触发
    trackCouponView(obj) {
      try {
        myAccountTrack.CouponView(obj);
      } catch (e) {
        console.warn('trackCouponView', e);
      }
    }
  }
};
