
export default {
  props: {
    tagLine: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      tagLineItem: {
        headDescription: '更多故事',
        linkDescription: '',
        link: ''
      }
    };
  },
  methods: {
    changeRouter(link) {
      // link处理
    }
  }
};
