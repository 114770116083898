
import { LibProductCard } from '@adidas-com-front/ui-library';
import tagLine from '../tag-line/TagLine.vue';
import mixins from '~/pages/index/track/track.mixins';
export default {
  name: 'FootPrint',
  components: { LibProductCard, tagLine },
  mixins: [mixins],
  data() {
    return {
      activeBanner: 0,
      swiperProgress: 0,
      slidePerView: 0,
      swiperOption: {
        watchOverflow: true,
        spaceBetween: 2,
        slidesPerView: 4,
        slidesPerGroup: 4,
        freeMode: false,
        breakpoints: {
          719: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            freeMode: true
          }
        },
        navigation: {
          nextEl: '.navigation-right',
          prevEl: '.navigation-left'
        },
        on: {
          slideChange: () => {
            this.swiperProgress = this.swiper.progress;
            this.activeBanner = this.swiper.activeIndex;
          }
        }
      },
      device: 'pc',
      benefitDescriptionHeight: '',
      swiperNavigationTop: '0px',
      footPrintData: []
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    this.init();
  },
  beforeDestroy() {
    this.swiper.destroy(false, false);
  },
  methods: {
    // 我的足迹卡片 埋点点击
    click(item, index) {
      this.trackRecentClick({
        articlenumber: item.articleId,
        productname: item.articleName,
        // index 位置 从1开始
        productposition: index + 1
      });
    },
    init() {
      this.getFootPrint();
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
      // 浏览足迹时,埋点
      let productlist = '';
      this.footPrintData.forEach((item, index) => {
        productlist += index === 0 ? item.articleId : '|' + item.articleId;
      });
      this.trackRecentView({
        producttotalnumber: this.footPrintData.length,
        productlist,
        pagetitle: 'Homepage'
      });
    },
    getFootPrint() {
      this.footPrintData = localStorage.getItem('pdpHistory')
        ? JSON.parse(localStorage.getItem('pdpHistory'))
        : [];
    },
    handleResize() {
      // 设置完轮播图属性 动态获取的图片高度确定导航的绝对位置
      this.device = window.innerWidth < 720 ? 'mobile' : '';
      setTimeout(() => {
        const imgDom = document.getElementsByClassName(
          'home-page-foot-print-card'
        )[0];
        if (!imgDom) { return; }
        this.swiperNavigationTop = (imgDom.clientWidth + 116) / 2 - 16 + 'px';
      }, 0);
    }
  }
};
