import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d21c9012&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=d21c9012&prod&lang=scss&scoped=true"
import style1 from "./index.vue?vue&type=style&index=1&id=d21c9012&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d21c9012",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClpMultiImage: require('/usr/src/app/packages/nuxt/components/clp/multi-image/index.vue').default})
