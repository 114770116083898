
export default {
  name: 'WxAppDownload',
  components: {
  },
  data() {
    return {
      // 遮罩显示标识
      isShow: false 
    };
  },
  mounted() {
    // 浏览器后退键监听
    if (window.history) {
      window.history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.popFun, false);
    }
  },
  methods: {
    // 展示遮罩
    show(cb) {
      if (typeof cb === 'function') {
        this.callback = cb;
      }
      this.isShow = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    // 隐藏遮罩
    hide() {
      window.history.go(-1);
      this.isShow = false;
      this.$nextTick(() => {
        window.removeEventListener('popstate', this.popFun, false);
      });
      this.callback();
      this.resolve();
    },
    // 浏览器后退键-隐藏遮罩
    popFun() {
      this.isShow = false;
      this.$nextTick(() => {
        window.removeEventListener('popstate', this.popFun, false);
      });
      this.callback();
      this.resolve();
    },
    // 点击空白处-隐藏遮罩
    clickView(e) {
      if (e.target.className.includes('download-view')) {
        this.hide();
      }
    }
  }
};
